import { isMobile } from "react-device-detect";
export default function CloseInformation(props) {
    if ( isMobile ) {
       return(
          <div class="information">
              <div class="inner">
                  <h1>重要なお知らせ</h1>
                  <p>HIRAQL NOTEは2025年3月18日に<br />サービスを終了いたします。<br />
                  詳細は<a href="https://hiraql.tokyu-laviere.co.jp/topics/news-202412-01">こちら</a></p>
              </div>
          </div>
       );
    }
    else {
       return(
          <div class="informationPC">
              <div class="inner">
                  <h1>重要なお知らせ</h1>
                  <p>HIRAQL NOTEは2025年3月18日にサービスを終了いたします。<br />
                  詳細は<a href="https://hiraql.tokyu-laviere.co.jp/topics/news-202412-01">こちら</a></p>
              </div>
          </div>
       );
    }

};
